document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('[data-user-signed-in="true"]')) {
    const timeoutLimit = document.querySelector('[data-timeout-in]').getAttribute('data-timeout-in');
    let timeout;

    function startTimer() {
      clearTimeout(timeout);
      timeout = setTimeout(signOutUser, timeoutLimit);
    }
    
    function signOutUser() {
      fetch("/users/sign_out", {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content // Only requests with this CSRF token are valid
        }
      }).then(() => {
        // Remember the last visited path by attaching it to the redirect_to param
        window.location.href = '/users/sign_in?redirect_to=' + window.location.pathname;
      });
    }

    startTimer();

    // Listen for form submissions
    document.addEventListener('submit', startTimer);

    // Listen for turbo/ajax navigation if using Turbo
    document.addEventListener('turbo:load', startTimer);
  }
});
