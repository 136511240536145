import consumer from "./consumer"

document.addEventListener("DOMContentLoaded", () => {
  consumer.subscriptions.create({ channel: "AssignRecordingToAnnotatorChannel" }, {
    // Required by Action Cable
    received(data) {
      const statusElement = document.getElementById(`recording-assigned-annotator-${data.recording_id}`);
      if (statusElement) {
        if (data.status === 'assigned') {
          statusElement.innerHTML = `By: ${data.user_name}`;
        } else if (data.status === 'unassigned') {
          statusElement.innerHTML = `
            <div class="text-center">
              <a href="#" data-recording-id="${data.recording_id}" class="text-blue text-decoration-underline fw-semibold">
                Assign to me
              </a>
            </div>
          `;
        }
      }
    }
  });
});
